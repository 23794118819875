<template>
  <div class="vacancy-card" :class="{ 'vacancy-card_draft': draft }" @click="goTo(`/vacancies/${vacancy.id}`)">
    <div class="vacancy-card__edit-btn">
      <img @click.stop="goTo(`/vacancies/${vacancy.id}/edit`)" src="@/assets/images/icons/pencil-black.svg" alt="edit">
    </div>
    <p class="vacancy-card__date">{{ vacancy.date }}</p>
    <h3 class="vacancy-card__title">{{ vacancy.title ? vacancy.title : 'Без названия' }}</h3>
    <p class="vacancy-card__active-tasks" :class="{ 'vacancy-card__active-tasks_active': vacancy.tasks_count }"
       v-if="!draft">
      Активные задачи:
      <span class="vacancy-card__active-tasks-num">{{ vacancy.tasks_count }}</span>
    </p>

    <div class="vacancy-card__responses" v-if="!draft" @click.stop="onVacancyCardClick">
      <p class="vacancy-card__responses-text"
         v-if="vacancy.user_responses_count || vacancy.user_responses_new_count">
        Отклики
        <span class="vacancy-card__responses-text-num">
                    {{ vacancy.user_responses_count }}
                    {{ vacancy.user_responses_new_count ? `(+${vacancy.user_responses_new_count})` : '' }}
                </span>
      </p>
      <p class="vacancy-card__responses-text" v-else>Нет откликов</p>
    </div>
  </div>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router"

const router = useRouter()
const route = useRoute()
const props = defineProps({
  vacancy: {
    type: Object,
    required: true
  },
  draft: {
    type: Boolean,
    default: false
  }
})

const onVacancyCardClick = () => {
  router.push({
    path: `/responses`,
    params: {vacancyId: props.vacancy.id},
    query: {vacancyId: props.vacancy.id},
    hash: route.hash,
  })
}

const goTo = (path) => {
  router.push({path})
}

</script>

<style scoped lang="scss">
.vacancy-card {
  position: relative;
  cursor: pointer;
  height: 181px;
  padding: 18px 16px 16px 16px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #0000000A;
  color: $dark;
  text-decoration: none;
  transition: .3s;

  &:hover {
    box-shadow: 2px 2px 24px 0px #1E39591F;
  }

  &:hover > .vacancy-card__edit-btn {
    display: flex;
  }

  &_draft {
    height: 85px;
    padding: 16px;
  }

  &__edit-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light;
    transition: .3s;
    cursor: pointer;
    display: none;
    z-index: +1;

    &:hover {
      background-color: $light-gray-1;
    }

    &:active {
      background-color: $light-gray-2;
    }
  }

  &__date {
    color: $gray;
    font-size: 12px;
  }

  &__title {
    max-width: 90%;
    height: 34px;
    margin-top: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: $dark;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    overflow: hidden;
  }

  &_draft &__title {
    margin-top: 4px;
  }

  &__active-tasks {
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: $dark-blue;
    font-size: 14px;
    font-weight: 400;
  }

  &__active-tasks-num {
    height: 16px;
    margin-left: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
  }

  &__responses {
    width: 100%;
    height: 48px;
    margin-top: 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light;
    color: $dark;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: $light-gray-1;
    }

    &:active {
      background-color: $light-gray-2;
    }
  }

  &__responses-text {
    font-size: 14px;
    font-weight: 500;
  }

  &__responses-text-num {
    color: $blue;
  }
}
</style>
