<template>
  <div class="modal-body">
    <div class="modal-body__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close"/>
    </div>

    <h3 class="modal-body__title">Создание вакансии</h3>

    <div class="modal-body__btns">
      <VButton label="Новая вакансия"
               link
               to="/vacancies/new"
               @click="$emit('close')"
               clickEffect
               class="modal-body__btn" />

      <VButton label="Выбрать шаблон"
               bg="rgba(13, 95, 203, 0.1)"
               bgHover="rgba(13, 95, 203, 0.3)"
               color="#0086B2"
               colorHover="#0086B2"
               link
               to="/templates"
               @click="$emit('close')"
               class="modal-body__btn" />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.modal-body {
  position: relative;
  padding: 40px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    color: $dark;
    font-size: 20px;
    font-weight: 600;
  }

  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  &__btn {
    width: 100%;
    transition: .3s;

    &:first-child {
     &:active {
       background-color: #006894 !important;
     }
    }

    &:last-child {
      margin-top: 12px;
      &:active {
          background-color: rgba(13, 95, 203, 0.5) !important;
      }
    }
  }
}
</style>
